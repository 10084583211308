import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

const CreateUserModal = ({ show, onHide, onSubmit }) => {
    const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm({
        defaultValues: {
            username: "",
            password: "",
            roleCode: "INTESA_LOCATION",
            serviceId: ""
        }
    });

    const onFormSubmit = (data) => {
        onSubmit(data);
        reset();
    };

    const handleCancel = () => {
        reset();
        onHide();
    };

    return (
        <Modal show={show} onHide={handleCancel} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create New User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            {...register("username", { required: "Username is required" })}
                            isInvalid={!!errors.username}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.username?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            {...register("password", { required: "Password is required" })}
                            isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.password?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Role</Form.Label>
                        <Controller
                            name="roleCode"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={[
                                        { value: "INTESA_LOCATION", label: "INTESA_LOCATION" },
                                        { value: "INTESA_MERCHANT", label: "INTESA_MERCHANT" },
                                    ]}
                                    onChange={(selectedOption) => setValue("roleCode", selectedOption.value)}
                                    isSearchable={false}
                                    value={{ label: field.value, value: field.value }}
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Service ID</Form.Label>
                        <Form.Control
                            type="text"
                            {...register("serviceId", { required: "Service ID is required" })}
                            isInvalid={!!errors.serviceId}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.serviceId?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCancel} className="me-2">
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Create User
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateUserModal;
