import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Select from "react-select";
import { exportVisibleDataToExcel } from "../utils/ExportToExcel";
import "bootstrap/dist/css/bootstrap.min.css";
import '../styles/SearchPage.css';

const voucherStatusOptions = [
    { value: "INACTIVE", label: "INACTIVE" },
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "ASSIGNED", label: "ASSIGNED" },
    { value: "EXPIRED", label: "EXPIRED" }
];

const SearchComponent = () => {
    const { register, handleSubmit, control } = useForm({
        defaultValues: {
            code: "",
            userReference: "",
            voucherStatus: "",
            voucherProvider: "",
            productCode: ""
        }
    });

    const gridRef = useRef();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [voucherProviders, setVoucherProviders] = useState([]);
    const [productCodes, setProductCodes] = useState([]);
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/search-filter`, {
                    method: "GET",
                    credentials: "include",
                });
                const data = await response.json();

                const formatOptions = (array, key) =>
                    Array.isArray(array)
                        ? array.map(item => ({ value: item[key], label: String(item[key]) }))
                        : [];

                setVoucherProviders(formatOptions(data.voucherProvider, "voucher_provider"));
                setProductCodes(formatOptions(data.productCode, "product_code"));
            } catch (error) {
                console.error("Error fetching search filters:", error);
            }
        };

        fetchFilters();
    }, []);

    const onSubmit = async (data) => {

        setLoading(true);
        setErrorMessage("");

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/filter-params`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();

            if (!response.ok) {
                if (response.status === 400 && result.message) {
                    setErrorMessage(result.message);
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            } else {
                setRowData(result);
            }
        } catch (error) {
            console.error("Error sending filter request:", error);
            setErrorMessage("An error occurred while fetching vouchers. Please try again.");
        } finally {
            setLoading(false);
        }
    };


    const columnDefs = [
        { headerName: "Voucher Provider", field: "provider" },
        { headerName: "Product Code", field: "productCode" },
        { headerName: "Voucher Code", field: "code" },
        { headerName: "User Reference", field: "userReference" },
        { headerName: "Status", field: "status" },
        { headerName: 'Created By', field: 'createdBy' },
        {
            headerName: 'Created At',
            field: 'createdAt',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: (filterDate, cellValue) => {
                    if (!cellValue) return -1;
                    const cellDate = new Date(cellValue);
                    return cellDate.getTime() - filterDate.getTime();
                },
            },
            valueFormatter: (params) => {
                if (!params.value) return '';
                return new Intl.DateTimeFormat('sr-RS', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }).format(new Date(params.value));
            },
        },
        {
            headerName: 'Updated At',
            field: 'updatedAt',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: (filterDate, cellValue) => {
                    if (!cellValue) return -1;
                    const cellDate = new Date(cellValue);
                    return cellDate.getTime() - filterDate.getTime();
                },
            },
            valueFormatter: (params) => {
                if (!params.value) return '';
                return new Intl.DateTimeFormat('sr-RS', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }).format(new Date(params.value));
            },
        },
        {
            headerName: 'Expiration Date',
            field: 'expirationDate',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: (filterDate, cellValue) => {
                    if (!cellValue) return -1;
                    const cellDate = new Date(cellValue);
                    return cellDate.getTime() - filterDate.getTime();
                },
            },
            valueFormatter: (params) => {
                if (!params.value) return '';
                return new Intl.DateTimeFormat('sr-RS', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }).format(new Date(params.value));
            },
        },
        {
            headerName: 'User Assigned Time',
            field: 'userAssignedTime',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: (filterDate, cellValue) => {
                    if (!cellValue) return -1;
                    const cellDate = new Date(cellValue);
                    return cellDate.getTime() - filterDate.getTime();
                },
            },
            valueFormatter: (params) => {
                if (!params.value) return '';
                return new Intl.DateTimeFormat('sr-RS', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }).format(new Date(params.value));
            },
        },
        {
            headerName: 'Activation Time',
            field: 'activationTime',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: (filterDate, cellValue) => {
                    if (!cellValue) return -1;
                    const cellDate = new Date(cellValue);
                    return cellDate.getTime() - filterDate.getTime();
                },
            },
            valueFormatter: (params) => {
                if (!params.value) return '';
                return new Intl.DateTimeFormat('sr-RS', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }).format(new Date(params.value));
            },
        },
        { headerName: "Activation Location", field: "activationLocation" }
    ];
    const defaultColDef = {
        flex: 1,
        filter: true,
        floatingFilter: true,
        sortable: true,
        resizable: true,
    };


    return (
        <div className="search-container">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="g-2 mb-3">
                                <Col md={4}>
                                    <Controller
                                        name="voucherProvider"
                                        control={control}
                                        render={({ field }) => (
                                            <Select {...field} options={voucherProviders} isClearable isSearchable placeholder="Voucher Provider" />
                                        )}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Controller
                                        name="productCode"
                                        control={control}
                                        render={({ field }) => (
                                            <Select {...field} options={productCodes} isClearable isSearchable placeholder="Product Code" />
                                        )}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Controller
                                        name="voucherStatus"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={voucherStatusOptions}
                                                isClearable
                                                isMulti
                                                placeholder="Voucher Status"
                                                onChange={(selectedOptions) => field.onChange(selectedOptions)}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="g-2 mb-3">
                                <Col md={4}>
                                    <Form.Control type="text" placeholder="Voucher Code" {...register("code")} />
                                </Col>
                                <Col md={4}>
                                    <Form.Control type="text" placeholder="User Reference" {...register("userReference")} />
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-center">
                                <Button variant="primary" type="submit">Search</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <div className="export-buttons">
                <Button variant="success" className="ms-2" onClick={() => exportVisibleDataToExcel(gridRef)}>
                    Export Excel
                </Button>
                {errorMessage && (
                    <div className="alert alert-danger text-center mt-3">
                        {errorMessage}
                    </div>
                )}
            </div>
            <div className='offer-details-table'>
                {loading && (
                    <div className="loading-overlay">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )}
                <div className="ag-theme-quartz mt-4" style={{ height: '100vh' }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        pagination={true}
                        paginationPageSize={100}
                        enableCellTextSelection={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default SearchComponent;