import React, { useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Button } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import NewLocationModal from '../components/NewLocationModal';
import { useAuthContext } from '../hooks/useAuthContext';
import { toast } from 'react-toastify';
import { FaEdit, FaTrash } from 'react-icons/fa';
import EditLocationModal from '../components/EditLocationModal';
import DeleteLocationModal from './DeleteLocationModal';
import localeText from '../utils/agGridLocale';


const fetchLocations = async ({ queryKey }) => {
    const merchantID = queryKey[1];
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/${merchantID}/locations`, {
        method: 'GET',
        credentials: 'include'
    });
    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Neuspelo preuzimanje lokacija: ${errorDetails.message}`);
    }
    return response.json();
};

const deleteLocation = async ({ merchantID, locationId }) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/delete-location/${merchantID}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ locationId }),
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error || 'Neuspelo brisanje lokacije');
    }
    return response.json();
};

const LocationsTable = ({ merchantID }) => {
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [locationToDelete, setLocationToDelete] = useState(null);
    const queryClient = useQueryClient();
    const { user } = useAuthContext()

    const { data: locations, isLoading, isError } = useQuery({
        queryKey: ['locations', merchantID],
        queryFn: fetchLocations
    });

    const mutation = useMutation({
        mutationFn: async (location) => {
            const isEditing = location.id !== undefined;
            const url = isEditing
                ? `${process.env.REACT_APP_API_BASE_URL}/merchants/locations/${location.id}`
                : `${process.env.REACT_APP_API_BASE_URL}/merchants/${merchantID}/newlocation`;

            const method = isEditing ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method,
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(location),
            });
            if (!response.ok) {
                const errorDetails = await response.text();
                throw new Error(`Greška prilikom čuvanja lokacije: ${errorDetails}`);
            }
            return response.json();
        },
        onMutate: async (newLocation) => {
            await queryClient.cancelQueries(['locations', merchantID]);
            const previousLocations = queryClient.getQueryData(['locations', merchantID]);

            queryClient.setQueryData(['locations', merchantID], (old) => {
                const optimisticLocation = { ...newLocation, id: newLocation.id || Date.now() };
                return newLocation.id
                    ? old.map((loc) => (loc.id === newLocation.id ? optimisticLocation : loc))
                    : [...(old || []), optimisticLocation];
            });

            return { previousLocations };
        },
        onSuccess: (data) => {
            if (data.updatedLocation) {
                toast.success(`Lokacija "${data.updatedLocation.name}" je uspešno izmenjena!`);
            } else if (data.newLocation) {
                toast.success(`Lokacija "${data.newLocation.name}" je uspešno kreirana!`);
            } else {
                toast.success('Lokacija je uspešno obrađena!');
            }
        },
        onError: async (err, location, context) => {
            try {
                const errorResponse = await err.json();
                if (errorResponse.missingFields) {
                    toast.error(`Polja koja nedostaju su: ${errorResponse.missingFields.join(', ')}`);
                } else if (errorResponse.error) {
                    toast.error(errorResponse.error);
                } else {
                    toast.error(
                        location.id
                            ? `Neuspešna izmena lokacije "${location.name}". Molim vas pokušajte ponovo.`
                            : `Neuspešno kreiranje nove lokacije "${location.name}". Molim vas pokušajte ponovo.`
                    );
                }
            } catch (parseError) {
                toast.error(
                    location.id
                        ? `Neuspešna izmena lokacije "${location.name}". Molim vas pokušajte ponovo.`
                        : `Neuspešno kreiranje nove lokacije "${location.name}". Molim vas pokušajte ponovo.`
                );
            }
            if (context?.previousLocations) {
                queryClient.setQueryData(['locations'], context.previousLocations);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(['locations', merchantID]);
        },
    });
    const deleteMutation = useMutation({
        mutationFn: deleteLocation,
        onMutate: async ({ locationId }) => {
            await queryClient.cancelQueries(['locations', merchantID]);

            const previousLocations = queryClient.getQueryData(['locations', merchantID]);
            const locationToDelete = previousLocations?.locations?.find(location => location.id === locationId);

            queryClient.setQueryData(['locations', merchantID], (old) =>
                old ? old.filter(location => location.id !== locationId) : old
            );

            return { previousLocations, locationToDelete };
        },

        onSuccess: (data, _, context) => {
            queryClient.invalidateQueries(['locations', merchantID]);

            const locationName = data?.locationName || context?.locationToDelete?.name;

            if (locationName) {
                toast.success(`Lokacija "${locationName}" uspešno obrisana!`);
            } else {
                toast.success('Lokacija uspešno obrisana!');
            }
        },

        onError: (err, _, context) => {
            toast.error(err.message || 'Došlo je do greške prilikom brisanja lokacije.');
            if (context?.previousLocations) {
                queryClient.setQueryData(['locations', merchantID], context.previousLocations);
            }
        },

        onSettled: () => {
            queryClient.invalidateQueries(['locations', merchantID]);
        },
    });


    const columns = useMemo(() => {
        const baseColumns = [
            { headerName: 'ID lokacije', field: 'id', filter: true },
            { headerName: 'Naziv prodavnice ', field: 'name', filter: true },
            { headerName: 'Adresa prodavnice', field: 'address', filter: true },
            { headerName: 'Korisničko ime', field: 'username', filter: true },
            {
                headerName: 'Fizička prodavnica',
                flex: 1,
                field: 'real_location',
                cellRenderer: (params) => {
                    return (
                        <input
                            type="checkbox"
                            disabled
                            checked={params.value}
                        />
                    );
                },
            },
        ];

        if (user.role === 'INTESA_SUPER_ADMIN') {
            baseColumns.push(
                {
                    headerName: 'Lokacija povezana sa korisnikom',
                    flex: 1,
                    field: 'is_linked',
                    cellRenderer: (params) => {
                        return (
                            <input
                                type="checkbox"
                                disabled
                                checked={params.value}
                            />
                        );
                    },
                },
                {
                    headerName: '',
                    cellRenderer: (params) => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FaEdit
                                style={{
                                    cursor: 'pointer',
                                    marginTop: '5',
                                    marginLeft: '10',
                                    color: '#0d6efd',
                                    transition: 'color 0.3s',
                                }}
                                size={26}
                                onMouseEnter={(e) => (e.currentTarget.style.color = '#084298')}
                                onMouseLeave={(e) => (e.currentTarget.style.color = '#0d6efd')}
                                onClick={() => handleEditClick(params.data)}
                            />
                            <FaTrash
                                style={{
                                    cursor: 'pointer',
                                    margin: '5px',
                                    color: '#FF0000',
                                    transition: 'color 0.3s',
                                }}
                                size={26}
                                onMouseEnter={(e) => (e.currentTarget.style.color = '#B22222')}
                                onMouseLeave={(e) => (e.currentTarget.style.color = '#FF0000')}
                                onClick={() => handleDeleteClick(params.data)}
                            />
                        </div>

                    ),
                }
            );
        }

        return baseColumns;
    }, [user.role]);


    const handleEditClick = (location) => {
        setCurrentLocation(location);
        setShowEditModal(true);
    };

    const handleCreateLocation = (newLocation) => {
        mutation.mutate(newLocation);
        setShowNewModal(false);
    };

    const handleEditLocation = (updatedLocation) => {
        mutation.mutate(updatedLocation);
        setShowEditModal(false);
    };

    const handleDeleteClick = (location) => {
        setLocationToDelete(location);
        setShowDeleteModal(true);
    };

    const confirmDelete = () => {
        if (locationToDelete) {
            deleteMutation.mutate({ merchantID, locationId: locationToDelete.id });
            setShowDeleteModal(false);
            setLocationToDelete(null);
        }
    };

    return (
        <div className='locations'>
            {isLoading && <div>Ucitava se...</div>}
            {isError && <div>Greška prilikom učitavanja lokacija.</div>}
            {!isLoading && !isError && (
                <div className="ag-theme-quartz" style={{ height: 400 }}>
                    {locations && locations.length > 0 ? (
                        <AgGridReact
                            rowData={locations}
                            columnDefs={columns}
                            pagination={true}
                            paginationPageSize={20}
                            enableCellTextSelection={true}
                            localeText={localeText}
                        />
                    ) : (
                        <div>Nema dostupnih lokacija.</div>
                    )}
                </div>
            )}
            <NewLocationModal
                show={showNewModal}
                handleClose={() => setShowNewModal(false)}
                onSubmit={handleCreateLocation}
            />

            <EditLocationModal
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                locationData={currentLocation}
                onSubmit={handleEditLocation}
            />
            <DeleteLocationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={confirmDelete}
                locationName={locationToDelete?.name}
            />
            {user?.role === 'INTESA_SUPER_ADMIN' && (
                <div className="new-offer-button" style={{ marginTop: '20px' }}>
                    <Button variant="primary" onClick={() => setShowNewModal(true)}>
                        Nova lokacija
                    </Button>
                </div>
            )}
        </div>
    );
};

export default LocationsTable;
