import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthContext } from './hooks/useAuthContext';
import { useLogout } from './hooks/useLogout';
import { useAutoLogout } from './hooks/useAutoLogout';
import NavigationTabs from './components/NavigationsTabs';
import ListOfMerchants from './pages/ListOfMerchants';
import ListOfCardholders from './pages/ListOfCardholders';
import MerchantDetails from './pages/MerchantDetails';
import CardholderDetails from './pages/CardholderDetails';
import OfferDetails from './pages/OfferDetails';
import Login from './pages/Login'
import MerchantLocation from './pages/MerchantLocation';
import PrivateRoute from './components/PrivateRoute'
import ErrorPage from './pages/ErrorPage';
import AllOffers from './pages/AllOffers';
import SearchComponent from './pages/SearchPage';
import Users from './pages/UserManagment';

function App() {
  const { user, loading } = useAuthContext()
  const { logout } = useLogout();

  useAutoLogout(user, logout);


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <div className="pages">
          <NavigationTabs />
          <Routes>
            <Route
              path="/"
              element={
                user ? (
                  user.role === 'INTESA_MERCHANT' ? (
                    <Navigate to={`/merchants/${user.service_id || 1}`} />
                  ) : (
                    <Navigate to="/merchants" />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path='/login'
              element={!user ? <Login /> : <Navigate to="/" />}
            />
            <Route
              path='/users'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_SUPER_ADMIN']}>
                  <Users />
                </PrivateRoute>}
            />
            <Route
              path='/merchants'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_SUPER_ADMIN', 'INTESA_ADMIN']}>
                  <ListOfMerchants />
                </PrivateRoute>}
            />
            <Route
              path='/cardholders'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_SUPER_ADMIN', 'INTESA_ADMIN']}>
                  <ListOfCardholders />
                </PrivateRoute>
              }
            />
            <Route
              path='/merchants/:id'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_SUPER_ADMIN', 'INTESA_ADMIN', 'INTESA_MERCHANT']}>
                  <MerchantDetails />
                </PrivateRoute>
              }
            />
            <Route
              path='/cardholders/:id'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_SUPER_ADMIN', 'INTESA_ADMIN']}>
                  <CardholderDetails />
                </PrivateRoute>
              }
            />
            <Route
              path='/merchants/:merchantId/offers/:offerId'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_SUPER_ADMIN', 'INTESA_ADMIN', 'INTESA_MERCHANT']}>
                  <OfferDetails />
                </PrivateRoute>
              }
            />
            <Route
              path='/location'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_LOCATION']}>
                  <MerchantLocation />
                </PrivateRoute>
              }
            />
            <Route
              path='/alloffers'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_SUPER_ADMIN', 'INTESA_ADMIN']}>
                  <AllOffers />
                </PrivateRoute>
              }
            />
            <Route
              path='/search'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_SUPER_ADMIN']}>
                  <SearchComponent />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
