import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { FaTrash, FaEdit } from 'react-icons/fa';
import '../styles/UserManagment.css';
import { toast } from 'react-toastify';
import DeleteModal from '../components/DeleteUserModal';
import EditModal from '../components/EditUserModal';
import CreateUserModal from '../components/CreateUserModal';

const fetchUsers = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/users`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('There was an error while loading users.');
    }
    return response.json();
};

const deleteUser = async ({ id }) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/delete`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw errorResponse;
    }
    return response.json();
};

const editUser = async ({ id, username, role, active, service_id }) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/edit`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, username, role, active, service_id }),
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw errorResponse;
    }
    return response.json();
};

const createUser = async ({ username, password, roleCode, serviceId, active }) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/signup`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password, roleCode, serviceId, active }),
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw errorResponse;
    }
    return response.json();
};

const Users = () => {
    const queryClient = useQueryClient();
    const { data: users, isLoading, isError } = useQuery({
        queryKey: ['users'],
        queryFn: fetchUsers,
    });

    const deleteMutation = useMutation({
        mutationFn: deleteUser,
        onMutate: async ({ id }) => {
            await queryClient.cancelQueries(['users']);

            const previousUsers = queryClient.getQueryData(['users']);

            const userToDelete = previousUsers?.users?.find(user => user.id === id);

            queryClient.setQueryData(['users'], (old) =>
                old ? { ...old, users: old.users.filter(user => user.id !== id) } : old
            );

            return { previousUsers, userToDelete };
        },

        onSuccess: (data, deletedUser, context) => {
            queryClient.invalidateQueries(['users']);
            toast.success(`User "${context?.userToDelete?.username || "Unknown"}" deleted successfully!`);
        },

        onError: async (err, context) => {
            toast.error(err.message || `An error occurred while deleting user "${context.userToDelete?.username}".`);
            if (context?.previousUsers) {
                queryClient.setQueryData(['users'], context.previousUsers);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(['users']);
        },
    });

    const editMutation = useMutation({
        mutationFn: editUser,
        onMutate: async (updatedUser) => {
            await queryClient.cancelQueries(['users']);

            const previousUsers = queryClient.getQueryData(['users']);

            queryClient.setQueryData(['users'], (old) =>
                old
                    ? {
                        ...old,
                        users: old.users.map((user) =>
                            user.id === updatedUser.id ? { ...user, ...updatedUser } : user
                        ),
                    }
                    : old
            );
            return { previousUsers };
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(['users']);
            toast.success(`User "${data.updatedUser.username}" updated successfully!`);
        },
        onError: async (err, updatedUser, context) => {
            console.error('Error updating user:', err);
            toast.error(err.message || `An error occurred while updating user ${updatedUser.username}.`);

            if (context?.previousUsers) {
                queryClient.setQueryData(['users'], context.previousUsers);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(['users']);
        },
    });

    const createMutation = useMutation({
        mutationFn: createUser,
        onSuccess: (data) => {
            queryClient.invalidateQueries(['users']);
            toast.success(`User "${data.user.username}" created successfully!`);
            setShowCreateModal(false);
        },
        onError: (err) => {
            toast.error(err.message || "An error occurred while creating the user.");
        },
    });

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleDeleteClick = (user) => {
        setSelectedUser(user);
        setShowDeleteModal(true);
    };

    const confirmDelete = () => {
        if (selectedUser) {
            deleteMutation.mutate({ id: selectedUser.id, username: selectedUser.username });
            setShowDeleteModal(false);
            setSelectedUser(null);
        }
    };

    const handleEditClick = (user) => {
        setSelectedUser(user);
        setShowEditModal(true);
    };


    const handleCreateClick = () => {
        setShowCreateModal(true);
    };

    const handleCreateUser = (newUser) => {
        createMutation.mutate(newUser);
    };

    const columns = [
        { headerName: 'User ID', field: 'id', flex: 0.5 },
        { headerName: 'Username', field: 'username' },
        { headerName: 'Role', field: 'role' },
        { headerName: 'Service ID', field: 'service_id', flex: 0.5 },
        { headerName: 'Location ID', field: 'location_id', flex: 0.5 },
        { headerName: 'Location Name', field: 'location_name' },
        { headerName: 'Location Address', field: 'location_address' },
        {
            headerName: 'Actions',
            field: 'actions',
            filter: false,
            flex: 0.5,
            cellRenderer: (params) => {
                const { role } = params.data;

                if (role === 'INTESA_ADMIN' || role === 'INTESA_SUPER_ADMIN') {
                    return null;
                }

                return (
                    <div style={{ display: 'flex' }}>
                        <FaEdit
                            style={{
                                cursor: 'pointer',
                                margin: '5px',
                                color: '#0d6efd',
                                transition: 'color 0.3s',
                            }}
                            size={26}
                            onMouseEnter={(e) => (e.currentTarget.style.color = '#084298')}
                            onMouseLeave={(e) => (e.currentTarget.style.color = '#0d6efd')}
                            onClick={() => handleEditClick(params.data)}
                        />
                        <FaTrash
                            style={{
                                cursor: 'pointer',
                                margin: '5px',
                                color: '#FF0000',
                                transition: 'color 0.3s',
                            }}
                            size={26}
                            onMouseEnter={(e) => (e.currentTarget.style.color = '#B22222')}
                            onMouseLeave={(e) => (e.currentTarget.style.color = '#FF0000')}
                            onClick={() => handleDeleteClick(params.data)}
                        />
                    </div>
                );
            },
        },
    ];

    const defaultColDef = {
        filter: true,
        floatingFilter: true,
        sortable: true,
        resizable: true,
        flex: 1
    };

    return (
        <div className="users">
            {isLoading && <div>Loading...</div>}
            {isError && <div>Error loading users.</div>}
            <div className='user-table'>
                {!isLoading && !isError && users && (
                    <div className="ag-theme-quartz" style={{ height: '80vh' }}>
                        {users?.users?.length > 0 ? (
                            <AgGridReact
                                rowData={users.users}
                                columnDefs={columns}
                                pagination={true}
                                defaultColDef={defaultColDef}
                                paginationPageSize={20}
                                enableCellTextSelection={true}
                            />
                        ) : (
                            <div>No available users.</div>
                        )}
                    </div>
                )}
                <div className="user-button-container">
                    <button className="btn btn-primary mb-3" onClick={handleCreateClick}>
                        Create User
                    </button>
                </div>
            </div>
            <DeleteModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={confirmDelete}
                username={selectedUser?.username}
            />
            <EditModal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                user={selectedUser}
                onSubmit={(updatedUser) => editMutation.mutate(updatedUser)}
            />
            <CreateUserModal
                show={showCreateModal}
                onHide={() => setShowCreateModal(false)}
                onSubmit={handleCreateUser}
            />
        </div>
    );
};

export default Users;
