import { Modal, Button } from 'react-bootstrap';

const DeleteLocationModal = ({ show, onHide, onConfirm, locationName }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Potvrda brisanja</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Da li ste sigurni da želite da obrišete lokaciju <strong>{locationName}</strong>?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Otkaži
                </Button>
                <Button variant="danger" onClick={onConfirm}>
                    Obriši
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteLocationModal;