import React, { useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

const EditModal = ({ show, onHide, user, onSubmit }) => {
    const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm({
        defaultValues: {
            id: '',
            username: '',
            role: '',
            active: false,
            service_id: ''
        }
    });

    useEffect(() => {
        if (user) {
            reset({
                id: user.id,
                username: user.username,
                role: user.role,
                active: user.active,
                service_id: user.service_id
            });
        }
    }, [user, reset]);

    const onFormSubmit = (data) => {
        onSubmit(data);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            {...register("username", { required: "Username is required" })}
                            isInvalid={!!errors.username}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.username?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Role</Form.Label>
                        <Controller
                            name="role"
                            control={control}
                            rules={{ required: "Role is required" }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={[
                                        { value: "INTESA_LOCATION", label: "INTESA_LOCATION" },
                                        { value: "INTESA_MERCHANT", label: "INTESA_MERCHANT" },
                                    ]}
                                    onChange={(selectedOption) => setValue("role", selectedOption.value)}
                                    value={{ label: field.value, value: field.value }}
                                    isSearchable={false}
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label="Active"
                            {...register("active")}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Service ID</Form.Label>
                        <Form.Control
                            type="text"
                            {...register("service_id", { required: "Service ID is required" })}
                            isInvalid={!!errors.service_id}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.service_id?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={onHide}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditModal;
